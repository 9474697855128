<template>
  <v-sheet dark class="transparent">
    <responsive-layout>
      <v-container style="min-height: 100vh" class="py-8">
        <v-row justify="start" align="center">
          <v-col cols="12" lg="6">
            <h2>Welcome to Pandora SMC Admin, {{ me.name }}</h2>
          </v-col>
        </v-row>
        <v-row justify="start" align="center">
          <v-col cols="12" lg="4" class="d-flex flex-column" style="gap: 1rem">
            <v-card title="Download registration report">
              <v-card-text class="text-left">
                A report of all users that have registered
              </v-card-text>
              <v-card-actions>
                <v-btn
                  outlined
                  color="primary"
                  @click.prevent="
                    download(userDetailsExportUrl, 'registration_report.csv')
                  "
                  >Download</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </responsive-layout>
  </v-sheet>
</template>

<script>
import axios from "axios";
import fileDownload from "js-file-download";
import { mapGetters } from "vuex";
import ResponsiveLayout from "../../layouts/ResponsiveLayout.vue";

export default {
  components: { ResponsiveLayout },
  data() {
    return {
      userDetailsExportUrl: this.$tracking.userDetailsExportUrl,
    };
  },

  computed: {
    ...mapGetters(["me"]),
  },

  methods: {
    async download(url, filename) {
      const response = await axios.get(url, {
        responseType: "blob",
      });

      fileDownload(response.data, filename);
    },
  },
};
</script>

<style scoped></style>
